export const missions = [
  {
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2F1-UK-Surgeons.jpg?alt=media&token=32ccd168-8849-4897-ada0-1a5bcdf95d10",
    title: "St. Mary's Hospital Lacor",
    location: "Uganda - Gulu",
    description:
      "After the success of our first mission back in October 2023, we are very excited to announce that we will be returning to St. Mary's Hospital Lacor to continue with our project in 2025.",
    date: "March 2025",
    status: "Upcoming",
    destination: "",
  },
  {
    imgUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/st-marys-hospital-lacor.jpg?alt=media&token=d2cc4c6e-bfbe-4f21-a507-7cb1be45aeaa",
    title: "St. Mary's Hospital Lacor",
    location: "Uganda - Gulu",
    description:
      "On the 14th of October 2023 we embarked on our first mission to St. Mary's Hospital Lacor in Gulu, Uganda. After 2 amazing weeks we are delighted to say that this mission went very successfully.",
    date: "October 2023",
    status: "Complete",
    destination:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/reports%2FUGANDA%20REPORT%20AO.pdf?alt=media&token=dee07838-a8b0-43e4-95eb-b5337de3bade",
  },
];

export const members = [
  {
    name: "Abigail Lau",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fteam%2FAbi.png?alt=media&token=916b1c0c-4add-4f77-b2d2-3bfb7f87f64e",
    roles: ["Clinician"],
    biography:
      "My name is Abi, and I currently work in the Anaesthetics and Intensive Care departments as a Resident Doctor at Southmead Hospital, Bristol. I've lived and worked all over the UK for my medical and Anaesthetics training and have experience working with projects in Kenya, the British Red Cross and St John Ambulance. I am delighted to have the opportunity to join and learn from the team at Lacore.",
  },
  {
    name: "Ahmed Emam",
    imageUrl:
      "https://pbs.twimg.com/profile_images/1325581572209258498/rYWS8zJi_400x400.jpg",
    roles: ["Clinician"],
    biography: "",
    cv: "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/cv%2Fahmed-cv.pdf?alt=media&token=4a7cfdd3-bab0-4065-8cb5-aadfc65402d9",
  },
  {
    name: "Antonio Orlando",
    imageUrl:
      "https://media.licdn.com/dms/image/C4E03AQHHkG9tpmpn2g/profile-displayphoto-shrink_800_800/0/1517463117926?e=2147483647&v=beta&t=RWODqQMnPgp9QYxwR2NtImfeoYY2p2Wk8dXZLvXLTIg",
    roles: ["Advisory Board", "Clinician"],
    biography:
      "I have been a Consultant Plastic Surgeon in Bristol since 1999 and specialise in Head and Neck Surgery and skin oncology. I deal with resections and reconstruction following cancer but I have experience in burns and trauma. Following a humanitarian mission to Togo, I witnessed how devastating health problems can be on patients and their families who live in extreme poverty. It was a life changing experience which awakened the desire to do give something back to those less fortunate who live in countries where access to healthcare is difficult or impossible. I was inspired to found the charity Cyrenaeus and to start this work, largely from the realisation that a relatively small effort on our part goes a long way in a poor country. My vision is to create a rolling programme of regular visits from various levels of individuals to provide reconstructive surgery where is greatly needed and, most importantly, to train and sponsor local health care workers. This, the nurturing of local surgeons, nurses and other personnel is the true lasting legacy of such work.",
  },
  {
    name: "Chris Wearn",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fteam%2Fchris.jpeg?alt=media&token=ba27fa49-7ae9-443d-8f34-917b39dc5236",
    roles: ["Clinician"],
    biography:
      "My name is Chris. I’m a Consultant burns and plastic surgeon in Bristol, UK. I am the clinical lead for the Adult Burns Service at Southmead Hospital and the South West UK Children’s Burns Centre based at Bristol Royal Children’s Hospital. \n\nMy interest in burns and plastic surgery began during a trip to rural South Africa in 2006 where I was first involved in the surgical treatment of children with burns. This inspired me to train in plastic surgery in prestigious units in the UK, Australia and New Zealand. During my training, I have been fortunate to work in six different burns services around the globe which has given me a broad experience in burn care and reconstructive surgery. \n\nI am passionate about burns education and training. I teach on the UWE Bristol Burns Course and I am an instructor on the UK Emergency Management of Severe Burns Course (EMSB). \n\nI am very grateful for the opportunity to travel to Uganda with the support of Cyrenaeus and I look forward to sharing and exchanging knowledge and skills with the team at Lacor Hospital.",
  },
  {
    name: "Enzo Facciuto",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fteam%2Fenzo.jpeg?alt=media&token=896a2079-cae6-4242-be37-6b5a331a5d44",
    roles: ["Clinician"],
    biography:
      "My name is Enzo. I live and work in Napoli. I am a maxillo-facial surgeon and otolaringologist. Previously I participated in five missions in West Africa, in Guinea and Togo. In the last mission I met Antonio and together we started thinking if a new project to bring reconstructive surgery were is most needed in Africa. I look forward to be part of this new experience of Cyrenaeus.",
    cv: "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/cv%2Fenzo-cv.pdf?alt=media&token=92b63983-3ef5-4de4-b78d-35029066a1ca",
  },
  {
    name: "Giulia Colavitti",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fteam%2Fgiulia.jpeg?alt=media&token=12b7c263-f131-4030-b8a3-8d9a4c106b47",
    roles: ["Advisory Board", "Clinician"],
    biography:
      "My name is Giulia, I am a plastic and reconstructive surgeon and my main interests is in reconstructive microsurgery. My patients have often suffered cancer or trauma. I grew up and trained in Italy before moving to the UK in 2017 were I now work as a consultant. I have greatly enjoyed previous missions to Pakistan where I shared my experience and expertise taking care of patients and educating future microsurgeons.",
  },
  {
    name: "Jim Mirabal",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fteam%2Fjim.jpeg?alt=media&token=3245c10d-9aed-4252-9835-ad22aeae1708",
    roles: ["Advisory Board"],
    biography:
      "Greetings! My name is Jim, I am an accountant and have known Antonio Orlando for many years. As I have looked after the admin of many charities, I was delighted to become a trustee of Cyrenaeus and use my admin skills to help our teams carry out their wonderful work for those in need.",
  },
  {
    name: "Kareena Francombe",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fteam%2Fkareena.jpeg?alt=media&token=8301719e-5b37-41fa-a3ee-09733ff69f76",
    roles: ["Clinician"],
    biography:
      "My name is Kareena, I am a theatre scrub nurse, I first started in theatre in general surgery in 1992 and after completing the theatre course I moved into Plastic Surgery in the Autumn of 1993. I think it was the following Summer that I first started working with Mr. Orlando. During my time in this specialty I have been fortunate enough to be involved in two trips to St. Petersburg and four trips to Ethiopia doing charity work as well as supporting several cadaver dissection courses. I look forward to offering some time and experience working in Uganda.",
  },
  {
    name: "Karen Highway",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fteam%2Fkarenh.jpg?alt=media&token=2f516ae8-6241-4ab6-a5e3-efa010bead86",
    roles: ["Clinician"],
    biography:
      "Hello, my name is Karen and for the past 12 years I have worked as a Clinical Nurse Specialist at the Regional Adult Burns Unit Southmead Hospital, Bristol. I support patients, carers & families in all aspects of burns management and care throughout their in-patient and outpatient journeys, including teaching and supporting nurse practitioners working in the community so that patients can continue their recovery at home. With over 30 years of nursing experience, I have had the privilege to work across acute settings of Burns and Plastic Surgery theatres, Regional Adult Burns Unit, Emergency Department, and Intensive Care. In these roles I have gained extensive burns & plastic surgery wound and nursing care knowledge and skills which I cascade through teaching and mentoring junior nursing staff through specialised burns competencies. Additionally, as part of the module teaching team at the University of the West of England (UWE) Burns Care & Rehabilitation MSc/Degree module, I mentor registered nurses and allied health professionals through their academic studies. I currently represent the Bristol Adult Burns Service on the British Burns Association National Emergency Management of Severe Burns Course (EMSB) Senate board updating course teaching material and supporting course faculties to set up new EMSB courses throughout the UK. For the last ten years I have volunteered at the national young adult burns survivor's camps to help them build a peer support network as they continue their rehabilitation journey.",
  },
  {
    name: "Katharine Wembridge",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/images%2Fteam%2Fkate.jpeg?alt=media&token=e933a09f-ce86-43a4-931c-711642f6ecdd",
    roles: ["Clinician"],
    biography:
      "Hi, My name is Kate and I have been an anaesthetist at Southmead hospital for 21 years. I have specialised in anaesthesia for burns, plastic surgery, and children over the years; working with Antonio and others to remove tumours, reduce scarring and improve function for many people. I was born in Tanzania and have spent lots of time in Africa, including wonderful Uganda. I am excited to help with healthcare in Uganda and look forward to meeting the team in Lacore.",
  },
  {
    name: "Lotte Hardman",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/cyrenaeus-870fa.appspot.com/o/lotte.png?alt=media&token=29e9f42f-9fba-4872-9fc7-7960d9250e0e",
    roles: ["Advisory Board", "Clinician"],
    biography:
      "My name is Lotte and I am a Plastic surgery trainee working in Southmead Hospital, Bristol. I have a MSc in Humanitarian studies which gives a grounding as to best principles of resource building in low and middle income countries like Uganda. I would like to help set up a trainee link to help support plastic surgery training in Lacor, while also learning from the amazing work the team there is already doing. In the past I have worked for the Colombian and Maltese Red Cross and represented asylum seekers through Medical Justice.",
  },
  {
    name: "Rhodri Davies",
    imageUrl:
      "https://media.licdn.com/dms/image/C4E03AQGgcj0QpnvDwA/profile-displayphoto-shrink_800_800/0/1658094804246?e=2147483647&v=beta&t=3YC8vqxWPnEeasuZfBjPUWjtgDAcm5uPAz-xG9d1gJ4",
    roles: ["Advisory Board", "Webmaster"],
    biography:
      "Shwmae! My name is Rhodri and I am a Software Engineer at American Express. I have recently applied study medicine, and in the meantime have taken on the task of being the sole web developer for Cyrenaeus. For now, I will remain seated at my computer, typing away, but one day I hope to join the rest of the team on the field, as a doctor.",
  },
];
